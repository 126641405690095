<template>
  <div>

    <Navbar/>
    <Overlay :overlay="isLoading"/>

    <v-container fluid>

      <v-row justify="center">

        <v-col cols="12" lg="6">

          <v-card class="shadow rounded-lg mt-3" flat>

            <v-card-title>
              Utilisateurs
              <v-spacer/>
              <v-btn color="primary" depressed fab small to="/">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text class="pa-0">

              <v-skeleton-loader v-if="isLoading" type="table"></v-skeleton-loader>


              <div class="px-4 px-lg-15 py-5">
                <v-text-field v-model="search"
                              :dense="!$vuetify.breakpoint.mobile"
                              filled
                              hide-details
                              placeholder="Recherche..."
                              prepend-inner-icon="mdi-magnify"
                              rounded></v-text-field>
              </div>

              <v-divider/>

              <v-data-table :headers="headers"
                            :items="users"
                            :items-per-page="itemsPerPage"
                            :page.sync="page"
                            :search="search"
                            hide-default-footer
                            @page-count="pageCount = $event">

                <template v-slot:item.sms_status="{ item }">
                  <v-chip :color="getStatus(item.sms_status)['color']" dark small>
                    {{ getStatus(item.sms_status)['name'] }}
                  </v-chip>
                </template>

              </v-data-table>

              <v-divider/>


              <v-pagination v-model="page"
                            :length="pageCount"
                            :total-visible="5"
                            class="py-3"
              ></v-pagination>

              <div class="px-15">
                <v-select v-model="itemsPerPage"
                          :items="[10,20,30,50,100,200,300,500]"
                          dense
                          filled
                          label="Ligne par page"
                          rounded></v-select>
              </div>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";
import Navbar from "@/views/home/components/Navbar.vue";

export default {
    components: {
        Navbar
    },
    data() {
        return {
            search: '',
            isLoading: false,
            users: [],
            headers: [
                {text: 'Nom complet', value: 'name'},
                {text: 'Numéro de tél', value: 'phone'},
                {text: 'SMS', value: 'sms_status'},
            ],

            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
        }
    },
    methods: {
        getList() {
            this.isLoading = true
            HTTP.get('/database/list').then(res => {
                this.isLoading = false
                this.users = res.data.data
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        getStatus(status) {
            if (status === 'pending') {
                return {
                    color: 'warning',
                    name: 'En attente',
                }
            }

            if (status === 'sent') {
                return {
                    color: 'success',
                    name: 'Envoyé',
                }
            }

            if (status === 'error') {
                return {
                    color: 'red',
                    name: 'Echèc',
                }
            }
        }
    },
    created() {
        this.getList()
    }
}
</script>

<style scoped>

</style>